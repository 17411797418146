export const BaseURL = "https://api.ownfood.com.bd/api/v1";
// export const BaseURL = "https://api.garikroybikroy.com/api/v1";
// export const BaseURL = "https://staging-api.ownfood.net/api/v1";
// export const BaseURL = "http://localhost:5000/api/v1";
// export const BaseURL = process.env.REACT_APP_API_URL;
/*=======================DashBoard Link ========================*/
export const DashBoardLink = "https://dashboard.ownfood.com";
// export const DashBoardLink = "http://localhost:3001"
export const FrontEndLink = "https://ownfood.com";
// export const FrontEndLink = "http://localhost:3000";
export const googleAPIKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
